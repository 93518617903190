import AbstractService from './AbstractService';
import MeetingModel from './models/MeetingModel';
import Auth from '../modules/Auth';
import moment from 'moment';

class MeetingServiceImpl extends AbstractService {
  constructor() {
    super();
    this.endpoint = 'meetings';
  }

  /**
   * Create meeting.
   *
   * @param {object} formData JSON containing data from meeting form
   */
  create(formData) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    var meeting = MeetingModel.fromModalFormData(formData);

    return this.post(this.endpoint, meeting.toJSON(), config);
  }

  /**
   * Get list of all meetings.
   */
  getMeetings() {
    var endpoint = 'meetings';
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Get sorted list of meeting for specified user.
   *
   * @param {string} userId ID of user
   */
  getMeetingsForUser(userId) {
    // TODO: limit this endpoint to return meetings only if user requested meeting for himself
    var endpoint = 'meetings/user/' + userId;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Get list of meeting for specified room.
   *
   * @param {string} roomId ID of room
   */
  getMeetingsForRoom(roomId) {
    var endpoint = 'meetings/room/' + encodeURIComponent(roomId);
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  getMeetingsForRoomAndDate(roomId, date, unit) {
    var endpoint = `meetings/room/${encodeURIComponent(roomId)}/date/${moment(
      date
    ).format('YYYY-MM-DD')}${unit ? '?unit=' + unit : ''}`;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Find meeting by using meeting ID.
   *
   * @param {string} meetingId ID of requested meeting
   */
  getMeeting(meetingId) {
    var endpoint = 'meetings/' + meetingId;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.get(endpoint, config);
  }

  /**
   * Update meeting with provided data set.
   *
   * @param {string} meetingId ID of meeting that should be updated
   * @param {object} formData JSON containing updated data from meeting form
   */
  updateMeeting(meetingId, formData) {
    var endpoint = 'meetings/' + meetingId;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    var meeting = MeetingModel.fromModalFormData(formData);

    return this.put(endpoint, meeting.toJSON(), config);
  }

  /**
   * Delete meeting with provided meeting ID.
   *
   * @param {string} meetingId ID of meeting that should be deleted
   */
  deleteMeeting(meetingId) {
    var endpoint = 'meetings/' + meetingId;
    const headers = {
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };

    return this.delete(endpoint, config);
  }

  getMeetingSuggestion = (roomId, name) => {
    const endpoint = 'meetings/room/' + roomId + '/' + name;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: Auth.getToken(),
    };
    var config = {
      headers: headers,
    };
    return this.get(endpoint, config);
  };
}

const MeetingService = new MeetingServiceImpl();

export default MeetingService;
