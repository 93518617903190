import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  badge: {
    width: 12,
    height: 12,
    marginRight: 4,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    display: 'inline-block',
    animation: '$toggleVisibility 3s infinite',
  },
  '@keyframes toggleVisibility': {
    '0%': {
      backgroundColor: 'unset',
    },
    '50%': {
      backgroundColor: '#ffad19',
    },
    '100%': {
      backgroundColor: 'unset',
    },
  },
}));

const TabLabel = ({ name, showBadge }) => {
  const [t] = useTranslation();
  const classes = useStyles();

  return (
    <>
      {showBadge && <span className={classes.badge} />}
      {t(name)}
    </>
  );
};

TabLabel.propTypes = {
  name: PropTypes.string.isRequired,
  showBadge: PropTypes.bool.isRequired,
};

export default TabLabel;
