import { withStyles, Grid, Typography, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  type_constants,
  user_document_status_constants,
} from '../../../../AppSettings';
import DocumentService from '../../../../services/DocumentService';
import moment from 'moment';
import i18n from '../../../../assets/i18n';
import Auth from 'modules/Auth';
import { UserService } from 'services';
import { VmsInput } from 'components';
import {
  CheckCircleOutline,
  ErrorOutlineOutlined,
  HelpOutlineOutlined,
} from '@material-ui/icons';
// import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
// import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
// import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
const styles = (theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  // iconSuccess: {
  //   color: '#28a745',
  // },
  // iconQuestion: {
  //   color: '#28a745',
  // },
  // iconWarrning: {
  //   color: '#28a745',
  // },
  rowDoc: {
    flex: 1,
    height: 28,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  docLeftIconContainer: {
    minWidth: 28,
    display: 'flex',
    justifyContent: 'center',
  },
  rowContent: {
    flex: 1,
    minWidth: 0,
  },
  documentStatus: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary8,
  },
  documentLabelItem: {
    flex: 1,
    display: 'flex',
    minWidth: 0,
    '& > a': {
      display: 'flex',
      minWidth: 0,
    },
  },
  documentLabel: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary6,
  },
  documentInput: {
    fontSize: 16,
    fontWeight: 500,
    paddingLeft: theme.spacing(3),
  },
  noDecoration: {
    textDecoration: 'none',
  },
  root: {
    marginTop: '20px',
    width: '100%',
  },
});

const VmsDialogRowDocumentCollection = (props) => {
  const {
    onChange,
    classes,
    documents,
    onClick,
    disabled,
    itemDataCy,
    textDocNameDataCy,
  } = props;

  const handleClickedOnDocument = (document) => (event) => {
    if (
      document.status === user_document_status_constants.COMPLETED &&
      (document.type === type_constants.QUESTIONNAIRE ||
        document.type === type_constants.FEEDBACK)
    ) {
      onClick(document)();
    }
  };

  return (
    <div className={classes.root}>
      {documents.map((doc, idx) => {
        const { required_for_entrance, expires_in } = doc.data;
        const valid_until = moment(doc.valid_until);
        const now = moment();

        const expires_in_days = expires_in
          ? valid_until.diff(now, 'days')
          : undefined;
        const expired = expires_in && valid_until.isBefore(now);
        const required_for_entrance_text = required_for_entrance
          ? `(${i18n.t('required_for_entrance')})`
          : '';
        const expires_in_text = `(${i18n.t('expires_in', {
          days: expires_in_days,
        })})`;

        let displayText;
        if (!required_for_entrance || !expires_in) {
          displayText = `${i18n.t('invite_form_filled_in')} ${moment(
            doc.last_status_change
          ).format(i18n.t('visitor_local_date'))}`;
        } else {
          if (!expired) {
            displayText = `${i18n.t(
              'invite_form_filled_in'
            )} ${expires_in_text}`;
          } else {
            displayText = `${i18n.t('expired')} ${required_for_entrance_text}`;
          }
        }

        return (
          <Grid
            data-cy={itemDataCy}
            container
            key={idx}
            wrap="nowrap"
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item className={classes.rowContent}>
              <Grid container alignItems="center" className={classes.rowDoc}>
                <Grid item>
                  {[
                    user_document_status_constants.COMPLETED,
                    user_document_status_constants.READ,
                    user_document_status_constants.SIGNED,
                  ].includes(doc.status) ? (
                    <CheckCircleOutline
                      style={{ color: '#ffa500' }}
                      className={classes.icon}
                    />
                  ) : doc.data.required_for_entrance ? (
                    <ErrorOutlineOutlined
                      className={classes.icon}
                      style={{ color: '#c70039' }}
                    />
                  ) : (
                    <HelpOutlineOutlined
                      style={{ color: '#6e6e6e' }}
                      className={classes.icon}
                    />
                  )}

                  {/* </IconButton> */}
                </Grid>
                <Grid item className={classes.documentLabelItem}>
                  <Typography
                    data-cy={textDocNameDataCy}
                    className={classes.documentLabel}
                    noWrap
                    onClick={handleClickedOnDocument(doc)}
                  >
                    {doc.data.name}
                  </Typography>
                </Grid>
                <Grid item className={classes.documentLabelItem}>
                  {doc.type === type_constants.SIGNED &&
                  doc.status === user_document_status_constants.SIGNED ? (
                    <a
                      href={DocumentService.api + doc.data.document.downloadUrl}
                      download
                      className={classes.noDecoration}
                    >
                      <Typography className={classes.documentStatus} noWrap>
                        {displayText}
                      </Typography>
                    </a>
                  ) : doc.type === type_constants.PERSONAL_DOCUMENT &&
                    doc._id ? (
                    <a
                      href={
                        UserService.api +
                        UserService.endpoint +
                        doc.user +
                        '/documents/' +
                        doc._id +
                        '/download?jwt=' +
                        Auth.getAuthToken()
                      }
                      download
                      className={classes.noDecoration}
                    >
                      <Typography className={classes.documentStatus} noWrap>
                        {`${i18n.t('document_status_uploaded')} ${moment(
                          doc.last_status_change
                        ).format(i18n.t('visitor_local_date'))}`}
                      </Typography>
                    </a>
                  ) : doc.status !== user_document_status_constants.PENDING &&
                    doc.type !== type_constants.FEEDBACK ? (
                    <Typography
                      className={classes.documentStatus}
                      noWrap
                      onClick={handleClickedOnDocument(doc)}
                    >
                      {displayText}
                    </Typography>
                  ) : (
                    <Typography
                      className={classes.documentStatus}
                      noWrap
                      onClick={handleClickedOnDocument(doc)}
                    >
                      {`${i18n.t(
                        'document_status_' + doc.status
                      )} ${required_for_entrance_text}`}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              {doc.type === type_constants.PERSONAL_DOCUMENT && (
                <Grid container alignItems="center" className={classes.rowDoc}>
                  <Grid item>
                    <IconButton
                      className={classes.iconLeft}
                      disabled={true}
                    ></IconButton>
                  </Grid>
                  <Grid item className={classes.documentLabelItem}>
                    <VmsInput
                      className={classes.documentInput}
                      textValue={doc.data.customName ?? ''}
                      inputName="customName"
                      placeholder={i18n.t('description')}
                      disabled={disabled}
                      onChange={onChange('rename', null, idx)}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

VmsDialogRowDocumentCollection.propTypes = {
  classes: PropTypes.object.isRequired,
  iconLeft: PropTypes.object,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        customName: PropTypes.string,
      }),
      status: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default withStyles(styles)(VmsDialogRowDocumentCollection);
