import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { VmsDialogRowDocumentCollectionPorter } from '../rows';

const PorterVisitTab = ({ porterState }) => {
  return (
    <Grid item>
      <VmsDialogRowDocumentCollectionPorter
        documents={porterState.visit.documents}
        disabled={true}
        onChange={() => {}}
        onClick={() => {}}
      ></VmsDialogRowDocumentCollectionPorter>
    </Grid>
  );
};

PorterVisitTab.propTypes = {
  porterState: PropTypes.object.isRequired,
};

export default PorterVisitTab;
