import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  AccessTime,
  ErrorOutlineOutlined,
  People,
  Person,
  PersonAdd,
  PersonOutline,
  SdCard,
} from '@material-ui/icons';
import EventNote from '@material-ui/icons/EventNote';
import { user_status } from 'AppSettings';
import commonStyles from 'assets/jss/commonStyles';
import { VmsInput } from 'components/common/material-ui';
import { useTranslation } from 'react-i18next';
import { VmsDialogRowDateTimePicker, VmsDialogRowTextField } from '../rows';

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
}));

const emptyFunc = () => {};

const PorterVisitTab = ({ porterState, handlePorterChangeData }) => {
  const [t] = useTranslation();
  const classes = useStyles();
  console.log('state', porterState);
  const isVisitorInside =
    porterState.visit.events.find((event) => event.type === 'signedIn') &&
    !porterState.visit.events.find((event) => event.type === 'signedOut');

  const userClosed = porterState.visit.user_status === user_status.CLOSED;

  return (
    <Grid item>
      <VmsInput
        rowClass={classes.dialogRow}
        iconLeft={<PersonOutline />}
        textValue={porterState.visit.user.name}
        placeholder={t('invite_form_name')}
        inputLabel={t('invite_form_name')}
        onChange={null}
        inputName={'name'}
        disabled={true}
      />
      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<Person />}
        textValue={porterState.visit.host.name}
        placeholder={t('invite_form_host')}
        inputLabel={t('invite_form_host')}
        onChange={null}
        inputName={'host'}
        disabled={true}
      />
      <VmsInput
        iconLeft={
          porterState.visit.visitorCard !== '' ? (
            <SdCard style={{ color: '#ffa500' }} />
          ) : (
            <SdCard />
          )
        }
        rowClass={classes.dialogRow2}
        placeholder={t('visitor_card')}
        inputName="visitorCard"
        textValue={porterState.visit.visitorCard}
        onChange={handlePorterChangeData}
        disabled={isVisitorInside}
      />
      <VmsDialogRowDateTimePicker
        rowClass={classes.dialogRow}
        iconLeft={<AccessTime />}
        dateValue={[porterState.visit.arrival, porterState.visit.departure]}
        onChange={[emptyFunc, emptyFunc]}
        placeholder={[t('invite_form_arrival'), t('invite_form_departure')]}
        disabled={true}
      />

      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<PersonAdd />}
        textValue={porterState.visit.assistant}
        placeholder={t('invite_form_assistant')}
        inputLabel={t('invite_form_assistant')}
        onChange={null}
        inputName={'assistant'}
        disabled={true}
      />
      <VmsInput
        rowClass={classes.dialogRow2}
        iconLeft={<People />}
        textValue={porterState.visit.deputy}
        placeholder={t('invite_form_deputy')}
        inputLabel={t('invite_form_deputy')}
        onChange={null}
        inputName={'deputy'}
        disabled={true}
      />
      <VmsDialogRowTextField
        rowClass={classes.dialogRowNote}
        iconLeft={<EventNote />}
        textValue={porterState.visit.note}
        inputName={'note'}
        placeholder={t('invite_form_note')}
        inputLabel={t('invite_form_note')}
        onChange={null}
        disabled={true}
        multiline={true}
        rows={5}
        maxLength={512}
      />
      <VmsDialogRowTextField
        rowClass={classes.dialogRowNoteSmall}
        iconLeft={<EventNote />}
        textValue={porterState.visit.porterNote}
        inputName={'porterNote'}
        placeholder={t('invite_form_note_porter')}
        inputLabel={t('invite_form_note_porter')}
        onChange={handlePorterChangeData}
        disabled={userClosed}
        multiline={true}
        rows={2}
        maxLength={256}
      />
    </Grid>
  );
};

PorterVisitTab.propTypes = {
  porterState: PropTypes.object.isRequired,
  handlePorterChangeData: PropTypes.func.isRequired,
};

export default PorterVisitTab;
