import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  IconButton,
  Checkbox,
  Menu,
  MenuItem,
  Typography,
  TableRow,
  Grid,
} from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import VmsTableHeader, {
  createTableHeaderState,
} from 'components/common/material-ui/VmsTableHeader';
import i18n from 'assets/i18n';
import classNames from 'classnames';
import tableStyles from 'assets/jss/tableStyles';
import { configuration_group, routes } from 'AppSettings';
import { openSnackbar } from 'components/common/bars/SnackBar';
import LocationSettingsDialog from './LocationSettingsDialog';
import { ConfigurationService, ExportService } from 'services';
import { emptySettingSearchState } from 'containers/settings/SettingsPage';
import { withBus } from 'react-bus';
import compose from 'recompose/compose';
import { handleOpenConfirmDialog } from '../../common/dialogs/ConfirmDialog';
import Auth from '../../../modules/Auth';
import { withCompanyDataContext } from 'components/common/context';

const styles = (theme) => ({
  ...tableStyles(theme),
  root: {
    height: '100%',
  },
  tableResetHeight: {
    height: 'auto',
  },
});

export const createEmptyCompanyTableState = () => {
  return {
    currentItem: {},
    menuAnchorEl: null,
    tableHeaderState: createTableHeaderState([]),
    items: [],
    openLocationSettingsDialog: false,
    selectedLocationSetting: null,
    highlightedId: '',
  };
};

export const editCompanyTableState = (companyTable) => {
  return {
    currentItem: companyTable.currentItem,
    menuAnchorEl: companyTable.menuAnchorEl,
    tableHeaderState: companyTable.tableHeaderState,
    items: companyTable.items,
    openLocationSettingsDialog: companyTable.openLocationSettingsDialog,
    selectedLocationSetting: companyTable.selectedLocationSetting,
    highlightedId: companyTable.highlightedId,
  };
};

class CompanyTable extends Component {
  constructor(props) {
    super(props);
    this.tableHeader = null;
    this.state = {
      isUserNotAdmin: !Auth.isUserAdmin(),
    };
  }

  componentDidMount() {
    this.props.bus.on('handleAddClick', this.busHandleAddClick);
    this.loadCompanySettings();
    this.setSearchSettings();
  }

  componentDidUpdate(prevProps) {
    const { settingSearchState } = this.props;
    if (
      settingSearchState.disableSearch !==
      prevProps.settingSearchState.disableSearch
    ) {
      this.setSearchSettings();
    }
  }

  componentWillUnmount() {
    this.props.bus.off('handleAddClick', this.busHandleAddClick);
    this.emptySearchState();
  }

  busHandleAddClick = (pathname) => {
    if (pathname === routes.SETTINGS_COMPANY) this.handleNewItem();
  };

  emptySearchState = () => {
    const { onChangeSearch } = this.props;
    onChangeSearch(emptySettingSearchState());
  };

  setSearchSettings = () => {
    const { settingSearchState, onChangeSearch } = this.props;
    if (settingSearchState.disableSearch) {
      settingSearchState.disableSearch = false;
      settingSearchState.suggestionService =
        ConfigurationService.suggestLocationConfigurations;
      settingSearchState.onSuggestionsClearRequested =
        this.handleCompanySuggestionsClearRequested;
      settingSearchState.onSuggestionSelected =
        this.handleCompanySuggestionSelected;
      settingSearchState.onChangeSearch = this.handleCompanySuggestionChange;
      settingSearchState.suggestionItemValue = (suggestion) => {
        return suggestion.key;
      };
      settingSearchState.filterSuggestionsResponse = (response) => {
        return response;
      };
      onChangeSearch(settingSearchState);
    }
  };

  handleCompanySuggestionsClearRequested = () => (event) => {
    const { companyTableState } = this.props;
    companyTableState.highlightedId = '';
    this.onChangeState();
  };

  handleCompanySuggestionSelected = (suggestion) => (event) => {
    if (suggestion) {
      this.highlightSuggestCompany(suggestion._id);
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = suggestion.key;
      onChangeSearch(settingSearchState);
    }
  };

  handleCompanySuggestionChange = (name, value) => (event) => {
    const field = name !== undefined ? name : event.target.name;
    const fieldValue = value !== undefined ? value : event.target.value;
    if (field === 'searchSettings') {
      const { settingSearchState, onChangeSearch } = this.props;
      settingSearchState.searchValue = fieldValue ? fieldValue : '';
      onChangeSearch(settingSearchState);
    }
  };

  highlightSuggestCompany = (_id) => {
    const { companyTableState } = this.props;
    const { items, tableHeaderState } = companyTableState;
    const { rowsPerPage } = tableHeaderState;
    const index = items.findIndex((u) => u._id === _id);
    const curPage = Math.floor(index / rowsPerPage);
    tableHeaderState.page = curPage;
    companyTableState.highlightedId = _id;
    companyTableState.tableHeaderState = tableHeaderState;
    this.onChangeState();
  };

  loadCompanySettings = () => {
    const self = this;
    ConfigurationService.getConfigurationsForGroup(
      configuration_group.LOCATION_SETTINGS
    )
      .then(function (response) {
        const companyState = self.props.companyTableState;
        companyState.items = response;
        editCompanyTableState(companyState);
        self.props.companyTableState.tableHeaderState = createTableHeaderState(
          companyState.items
        );
        self.onChangeState();
      })
      .catch(function (error) {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  onChangeState = () => {
    this.props.onChange(this.props.companyTableState);
  };

  setTableHeaderRef = (tableHeader) => {
    this.tableHeader = tableHeader;
  };

  handleEditItem = (item) => (event) => {
    const { isUserNotAdmin } = this.state;
    if (!isUserNotAdmin) {
      const editItem = JSON.parse(JSON.stringify(item));
      this.handleMenuClose();
      this.props.companyTableState.currentItem = editItem;
      this.props.companyTableState.openLocationSettingsDialog = true;
      this.onChangeState();
    }
  };

  handleNewItem = () => {
    this.props.companyTableState.currentItem = {};
    this.onChangeState();
    this.handleopenLocationSettingsDialog();
  };

  handleCloseLocationSettingsDialog = () => {
    this.props.companyTableState.openLocationSettingsDialog = false;
    this.onChangeState();
  };

  handleSaveLocationSettingsDialog = () => {
    const {
      companyDataContext,
      companyTableState: { currentItem },
    } = this.props;
    let config = {
      key: currentItem.key,
      id: currentItem._id,
      value: currentItem.value,
      group: configuration_group.LOCATION_SETTINGS,
    };

    ConfigurationService.updateConfigurationForGroup(
      configuration_group.LOCATION_SETTINGS,
      config
    )
      .then((response) => {
        openSnackbar(i18n.t('settings_company_location_saved'));
        companyDataContext.revalidateCompanyData();
        this.handleCloseLocationSettingsDialog();
        this.loadCompanySettings();
      })
      .catch((error) => {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  handleDialogChange = (key) => (event) => {
    let item = this.props.companyTableState.currentItem;
    if (key === 'key') {
      item.key = event.target.value;
      this.props.companyTableState.currentItem = item;
    }
    if (key === 'value') {
      item.value = event.target.value;
      this.props.companyTableState.currentItem = item;
    }
    this.onChangeState();
  };

  handleopenLocationSettingsDialog = () => {
    this.props.companyTableState.openLocationSettingsDialog = true;
    this.onChangeState();
  };

  handleMenuOpen = (event, item) => {
    this.props.companyTableState.menuAnchorEl = event.currentTarget;
    this.props.companyTableState.currentItem = item;
    this.onChangeState();
  };

  handleMenuClose = () => {
    this.props.companyTableState.menuAnchorEl = null;
    this.props.companyTableState.currentItem = {};
    this.onChangeState();
  };

  handleDeleteItemOpenDialog = (itemId) => (event) => {
    this.handleMenuClose();
    handleOpenConfirmDialog(
      i18n.t('settings_company_dialog_remove_title'),
      i18n.t('settings_company_dialog_remove_description'),
      this.handleDeleteItem(itemId)
    );
  };

  handleDeleteItem = (itemId) => (event) => {
    const { companyDataContext } = this.props;
    ConfigurationService.deleteConfiguration(itemId)
      .then((response) => {
        openSnackbar(i18n.t('removed'));

        companyDataContext.revalidateCompanyData();

        this.handleMenuClose();
        this.loadCompanySettings();
      })
      .catch((error) => {
        openSnackbar(error.message);
      });
    this.handleMenuClose();
  };

  handleClickRow = (item) => (event) => {
    this.tableHeader.handleClickRow(item);
  };

  handleChangeTableHeader = (tableHeaderState) => {
    this.props.companyTableState.tableHeaderState = tableHeaderState;
    this.onChangeState();
  };

  handleRemoveSelectedItemsOpenDialog = () => {
    this.handleMenuClose();
    handleOpenConfirmDialog(
      i18n.t('settings_company_dialog_remove_selected_title'),
      i18n.t('settings_company_dialog_remove_selected_description'),
      this.handleRemoveSelectedItems()
    );
  };

  handleRemoveSelectedItems = () => (event) => {
    const { items, tableHeaderState } = this.props.companyTableState;
    const selected = tableHeaderState.selected;
    var array = items;
    var self = this;
    const sumOfAll = selected.length;
    let deleted = 0;
    selected.forEach((item) => {
      const index = items.findIndex((q) => q._id === item._id);
      array.splice(index, 1);
      ConfigurationService.deleteConfiguration(item._id)
        .then(function (response) {
          deleted++;
          if (deleted === sumOfAll) {
            openSnackbar(i18n.t('removed'));
            self.props.companyTableState.items = array;
            self.props.companyTableState.tableHeaderState.selected = [];
            self.onChangeState();
          }
        })
        .catch(function (error) {
          openSnackbar(error.message);
          self.loadCompanySettings();
        });
    });
  };

  isSelected = (item) =>
    this.props.companyTableState.tableHeaderState.selected.findIndex(
      (i) => i._id === item._id
    ) !== -1;

  render() {
    const { classes, companyTableState } = this.props;
    const {
      menuAnchorEl,
      tableHeaderState,
      currentItem,
      items,
      openLocationSettingsDialog,
      selectedLocationSetting,
      highlightedId,
    } = companyTableState;
    const { rowsPerPage, page, selected } = tableHeaderState;
    const { isUserNotAdmin } = this.state;

    return (
      <div>
        <Grid container>
          <Grid
            item
            className={classNames(
              classes.settingsTableGridContent,
              classes.tableResetHeight
            )}
          >
            <Table className={classes.table}>
              <VmsTableHeader
                onRef={this.setTableHeaderRef}
                onChange={this.handleChangeTableHeader}
                tableHeaderState={tableHeaderState}
                onRemoveSelectedItems={this.handleRemoveSelectedItemsOpenDialog}
                exportData={ExportService.configurations(selected)}
                exportDataFilename={'company_location_settings.csv'}
                limitedView={isUserNotAdmin}
                prevPageButtonDataCy="settings-company-button-previous-page"
                nextPageButtonDataCy="settings-company-button-next-page"
              />
              <TableBody>
                {items
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => {
                    const isSelected = this.isSelected(item);
                    return (
                      <TableRow
                        key={item._id}
                        hover
                        role="checkbox"
                        selected={isSelected || highlightedId === item._id}
                        aria-checked={isSelected}
                        className={classes.tableRow}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellCheckbox
                          )}
                        >
                          {!isUserNotAdmin && (
                            <Checkbox
                              checked={isSelected}
                              color="primary"
                              onClick={this.handleClickRow(item)}
                            />
                          )}
                        </TableCell>

                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellAlignLeft
                          )}
                          padding="none"
                          onClick={this.handleEditItem(item)}
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableInfoFont
                            )}
                            noWrap
                          >
                            {item.key}
                          </Typography>
                        </TableCell>

                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.textAlignRight
                          )}
                          padding="none"
                          onClick={this.handleEditItem(item)}
                        >
                          <Typography
                            variant="body2"
                            className={classNames(
                              classes.header,
                              classes.tableInfoFont
                            )}
                            noWrap
                          >
                            {item.value}
                          </Typography>
                        </TableCell>

                        <TableCell></TableCell>

                        <TableCell
                          scope="row"
                          className={classNames(
                            classes.tableCell,
                            classes.tableCellCheckbox
                          )}
                          padding="none"
                        >
                          {!isUserNotAdmin && (
                            <IconButton
                              className={classes.tableMenuButton}
                              aria-owns={menuAnchorEl ? 'table-menus' : null}
                              aria-haspopup="true"
                              onClick={(event) =>
                                this.handleMenuOpen(event, item)
                              }
                            >
                              <MoreHoriz className={classes.tableIcon} />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Menu
          id="table-menu"
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={this.handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={this.handleEditItem(currentItem)}>
            {i18n.t('menu_edit')}
          </MenuItem>
          <MenuItem onClick={this.handleDeleteItemOpenDialog(currentItem._id)}>
            {i18n.t('menu_delete')}
          </MenuItem>
        </Menu>
        <LocationSettingsDialog
          open={openLocationSettingsDialog}
          title={
            JSON.stringify(currentItem) !== '{}'
              ? i18n.t('settings_company_location_settings_edit')
              : i18n.t('settings_company_location_settings_add')
          }
          inputValue={selectedLocationSetting}
          onClose={this.handleCloseLocationSettingsDialog}
          onSave={this.handleSaveLocationSettingsDialog}
          selectedItem={currentItem}
          onChange={this.handleDialogChange}
        />
      </div>
    );
  }
}

CompanyTable.propTypes = {
  companyDataContext: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  companyTableState: PropTypes.object.isRequired,
};

export default compose(
  withCompanyDataContext(),
  withBus(),
  withStyles(styles, { withTheme: true })
)(CompanyTable);
